module controllers {
    export module customs {

        interface IAccountCtrlScope extends ng.IScope {
        }

        interface IAccountParams extends ng.ui.IStateParamsService {
        }

        export class accountListCtrl {
            static $inject = ["$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "entityService",
                "$timeout",
                "$uibModal",
                "accountService",
                "$state",
                "$stateParams",
                "classificationValueService",
                "countryService",
                "customsOfficeCodeService",
                "customsPartyService",
                "$transitions",
                'menuService'
            ];
            showGrid: boolean = false;
            IsLoading: boolean = false;
            gridApi: uiGrid.IGridApi;
            apiList: uiGrid.IGridApi;
            selected: uiGrid.IGridRow;
            selectedRowsArray: Array<interfaces.customs.IAccountDisplay> = [];
            allDirtyRows: Array<interfaces.customs.IAccountDisplay>;
            searchAccordian: boolean = false;
            myHook: any;

            accSearch: interfaces.customs.IAccountSearch = <interfaces.customs.IAccountSearch>{
            };

            dropdownsObject = {
                Entities: Array<interfaces.applicationcore.IEntity>(),
            };

            declarationCountryId: number = 0;
            declarationCountryList: Array<interfaces.applicationcore.IDropdownModel>;
            declarationCountry: interfaces.applicationcore.IDropdownModel;

            customsOfficeId: number = 0;
            customsOfficeList: Array<interfaces.applicationcore.IDropdownModel>;
            customsOffice: interfaces.applicationcore.IDropdownModel;

            customsPartyId: number = 0;
            customsPartyList: Array<interfaces.applicationcore.IDropdownModel>;
            customsParty: interfaces.applicationcore.IDropdownModel;

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };

            filterNames: string[] = ["EntityCode", "DeclarationCountryCode", "CustomsOfficeCode", "Number", "CustomsParty", "IsActive"];
            filterList: interfaces.applicationcore.IKeyValue[] = [];

            ctrlName: string;
            ownerEntityId: number;
            entityList: ng.resource.IResourceArray<interfaces.applicationcore.IEntity>;
            sortName: string;
            sortDirection: string;

            hasCreateRight: boolean = false;

            constructor(private $scope: IAccountCtrlScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private accountService: interfaces.customs.IAccountService,
                private $state: ng.ui.IStateService,
                private $stateParams: IAccountParams,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private countryService: interfaces.applicationcore.ICountryService,
                private customsOfficeCodeService: interfaces.tariff.ICustomsOfficeCodeService,
                private customsPartyService: interfaces.master.ICustomsPartyService,
                private $transitions: ng.ui.core.ITransition,
                private menuService: interfaces.applicationcore.IMenuService
            ) {
                this.getCreateRight();
                
                this.ctrlName = 'Account List Controller';

                this.loadControls();

                this.loadAccounts();

                $timeout(() => {
                    this.showGrid = true;
                });

                this.myHook = $transitions.onSuccess({
                    to: 'auth.Account',
                    from: 'auth.Account.**'
                }, () => {
                    this.loadAccounts();
                });

                $scope.$on('$destroy', () => {
                    $transitions.onStart({}, this.myHook)
                });
            }

            getCreateRight() {
                this.hasCreateRight = false;
                
                return this.menuService.getGTSConnectMenuItem(641).get((result: interfaces.applicationcore.IMenu) => {
                    if (result && result.name) {
                        this.hasCreateRight = true;
                    }
                }).$promise;
            }

            loadAccounts() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'AccountList'
                },
                    () => {
                        var searchObject: interfaces.applicationcore.ISearchObject = {
                            filters: [],
                            sorts: []
                        }

                        angular.forEach(this.accSearch, (n, key) => {
                            if (n["Id"]) {
                                searchObject.filters.push({ Name: key, Value: n.Id });
                            } else {
                                searchObject.filters.push({ Name: key, Value: n });
                            }
                        });

                        if (this.apiList) {
                            var grid = this.apiList.grid;
                            angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                if (n.filters[0].term) {
                                    searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                                }

                                if (n.sort.direction) {
                                    searchObject.sorts.push({
                                        Name: n.name,
                                        SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                        SortOrder: n.sort.priority
                                    });
                                }
                            });
                        }

                        var params = {
                            connectSearch: searchObject,
                            numberRecords: this.paginationOptions.pageSize,
                            pageNumber: this.paginationOptions.pageNumber,
                        }

                        return this.accountService.getList().query(params, (result: Array<interfaces.customs.IAccountDisplay>) => {
                            this.gvwAccList.data = result;
                            this.selectedRowsArray = [];
                            if (result[0]) {
                                this.gvwAccList.totalItems = result[0].NumRecs;
                            } else {
                                this.gvwAccList.totalItems = 0;
                            }
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            gvwAccList: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: true,
                enableCellEdit: false,
                useExternalSorting: true,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: true,
                enableFullRowSelection: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: true,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                onRegisterApi: (gridApi) => {
                    this.registerGridApi(gridApi)

                    this.apiList.cellNav.on.navigate(this.$scope, (newCol) => {
                        this.$timeout(() => {
                            this.apiList.selection.selectRow(newCol.row.entity);
                        });
                    });
                },
                rowTemplate: `<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader, 'SLA-amber': row.entity.WarningLevel === 1, 'SLA-danger': row.entity.WarningLevel === 2 }" ui-grid-cell></div>`,
                columnDefs: [{
                    name: "Id",
                    displayName: "Id",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                },
                    {
                        name: "EDIT",
                        displayName: "",
                        enableFiltering: false,
                        cellTemplate: `
                            <div class="GridButton"> 
                                <button type="button" ng-click="grid.appScope.accountListCtrl.editAcc(row.entity.Id)" class="btn btn-default btn-sm">
                                    <span class="fa fa-pencil"></span>
                                </button>
                            </div>`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        width: 35
                    },
                    {
                        name: "EntityCode",
                        displayName: "Entity",
                        field: "EntityCode",
                        width: 80,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                    },
                    {
                        name: "DeclarationCountryCode",
                        displayName: "Country",
                        field: "DeclarationCountry",
                        width: 80,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                    },
                    {
                        name: "CustomsOfficeCode",
                        displayName: "Office Code",
                        field: "CustomsOfficeCode",
                        width: 150,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                    },
                    {
                        name: "Number",
                        displayName: "Account Number",
                        field: "AccountNumber",
                        width: 150,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                    },
                    {
                        name: "Description",
                        displayName: "Description",
                        field: "Description",
                        width: 200,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                    },
                    {
                        name: "CustomsParty",
                        displayName: "Customs Party",
                        field: "CustomsParty",
                        width: 150,
                        filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                    },
                    {
                        name: "Balance",
                        displayName: "Current Balance",
                        field: "CurrentBalance",
                        width: 150,
                        enableFiltering: false,
                        type: "number",
                        cellFilter: "number: 2",
                    },
                    {
                        name: "BondAmount",
                        displayName: "Bond Amount",
                        field: "BondAmount",
                        width: 150,
                        enableFiltering: false,
                        type: "number",
                        cellFilter: "number: 2",
                    },
                    {
                        name: "CurrencyCode",
                        displayName: "Currency",
                        field: "CurrencyCode",
                        width: 80,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`

                    },
                    {
                        name: "IsActive",
                        displayName: "Active?",
                        field: "IsActive",
                        width: 80,
                        filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                    }]
            };

            
            registerGridApi(gridApi: uiGrid.IGridApi) {

                this.showAll_click();
                this.apiList = gridApi;

                this.apiList.core.on.filterChanged(this.$scope, () => { this.ListFilterChange(gridApi) });
                this.apiList.core.on.sortChanged(this.$scope, () => { this.ListShortChange(gridApi) });
                this.apiList.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ListPageChange(newPage, pageSize) });

                this.apiList.selection.on.rowSelectionChanged(this.$scope, (selected) => { this.selected = selected; });

                this.$timeout(() => {
                    if (this.selected)
                        this.apiList.selection.selectRow(this.selected.entity);
                });
            }
            ListFilterChange(gridApi: uiGrid.IGridApi) {
                _.forEach(gridApi.grid.columns, (c) => {
                    if (!c.filters[0].term) {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', c.field));

                        if (keyValue) {
                            this.filterList.splice(this.filterList.indexOf(keyValue), 1);
                        }
                    }
                });

                this.DoSearch();
            }   
           
            ListShortChange(gridApi) {
                this.DoSearch();
            }
            comListShortChange(gridApi) {
                this.DoSearch();
            }

            comListFilterChange(gridApi: uiGrid.IGridApi) {
                this.DoSearch();
            }

            public editAcc(id: number) {
                this.$state.go("auth.MasterData.Account.Update", { accId: id });
            }

            public createAcc() {
                this.$state.go("auth.MasterData.Account.Create", { accId: 0 });
            }


            ListPageChange(newPage, pageSize) {
                var dirtyRows = _.map(this.apiList.rowEdit.getDirtyRows(), (value) => { return value.entity; });
                if (this.allDirtyRows) {
                    _.forEach(dirtyRows, (value) => {
                        //If a dirty row exists in the new dirty rows then overwrite the old one.
                        var row: interfaces.customs.IAccountDisplay = _.find(this.allDirtyRows, _.matchesProperty('Id', value.Id));

                        if (row) {
                            //Row exists with the same Id.
                            var i: number = _.indexOf(this.allDirtyRows, row);
                            this.allDirtyRows[i] = row;
                        }
                        else {
                            this.allDirtyRows = _.concat(this.allDirtyRows, value);
                        }
                    });
                }
                else {
                    this.allDirtyRows = dirtyRows;
                }

                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;

                this.DoSearch();
            }

            loadControls() {
                this.DoSearch();
            }

            showAll_click() {
                this.searchAccordian = false;
                if (this.apiList !== undefined) {
                    this.apiList.grid.clearAllFilters(true, true, true).then(() => {
                        this.apiList.grid.resetColumnSorting(null);
                        this.DoSearch();
                    });
                } else {
                    this.DoSearch();
                }
            }

            search_click() {
                this.searchAccordian = false;
                this.DoSearch();
            }

            downloadToExcel() {
                this.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                angular.forEach(this.accSearch, (n, key) => {

                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.apiList) {

                    var grid = this.apiList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }
                    });
                }

                var params = {
                    connectSearch: searchObject
                }

                this.accountService.getAccListExcel(params).then(() => {
                    this.IsLoading = false;
                }, (data) => {
                    this.IsLoading = false;
                    this.gvwAccList.data = [];
                    this.gvwAccList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }
            DoSearch() {
                this.IsLoading = true;

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'AccountList'
                },
                    () => {
                        var searchObject: interfaces.applicationcore.ISearchObject = {
                            filters: [],
                            sorts: []
                        }

                        angular.forEach(this.accSearch, (n, key) => {
                            if (n) {
                                if (n["Id"]) {
                                    searchObject.filters.push({ Name: key, Value: n.Id });
                                } else {
                                    if (n instanceof moment) {
                                        searchObject.filters.push({ Name: key, Value: n });
                                    } else {
                                        searchObject.filters.push({ Name: key, Value: n });
                                    }
                                }
                            }
                        });

                        if (this.apiList) {
                            var grid = this.apiList.grid;
                            angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                if (n.filters[0].term) {
                                    searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                                }

                                if (n.sort.direction) {
                                    searchObject.sorts.push({
                                        Name: n.name,
                                        SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                        SortOrder: n.sort.priority
                                    });
                                }
                            });
                        }

                        var params = {
                            connectSearch: searchObject,
                            numberRecords: this.paginationOptions.pageSize,
                            pageNumber: this.paginationOptions.pageNumber,
                        }

                        return this.accountService.getList().query(params, (result: Array<interfaces.customs.IAccountDisplay>) => {
                            var currentDirtyRows: Array<interfaces.customs.IAccountDisplay> = [];

                            _.forEach(this.allDirtyRows, function (value) {
                                var row: interfaces.customs.IAccountDisplay = _.find(result, _.matchesProperty('Id', value.Id));

                                if (row) {
                                    var i: number = _.indexOf(result, row);
                                    result[i] = value;
                                    currentDirtyRows.push(result[i]);
                                }
                            });

                            this.gvwAccList.data = result;
                            this.selectedRowsArray = [];

                            if (currentDirtyRows.length > 0) {
                                this.$timeout(() => {
                                    _.forEach(currentDirtyRows, (value) => {
                                        this.apiList.rowEdit.setRowsDirty(currentDirtyRows);
                                    });
                                });
                            }

                            this.IsLoading = false;

                            if (result[0]) {
                                this.gvwAccList.totalItems = result[0].NumRecs;
                            } else {
                                this.gvwAccList.totalItems = 0;
                            }
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }
        }

        angular.module("app").controller("accountListCtrl", controllers.customs.accountListCtrl);
    }
}